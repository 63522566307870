/* eslint-disable import/no-default-export */

// Import the license injector before mounting any react component
import './MUILicense.js'

import { Leva } from 'leva'
import { type ReactNode } from 'react'
import { KnobsContainer } from '@workwave-tidal/stories-helpers'
import { TidalThemeProvider } from '@workwave-tidal/tidal/styles'
import { themeConfig } from '../themeConfig.js'

type Props = { children: ReactNode }

export default function MainDecorator(props: Props) {
  const { children } = props

  // TODO: connect the theme to a custom Theme config UI

  return (
    <>
      {/* until Leva will support React@18 we need to mount its root component before any other story */}
      <KnobsContainer width={400}>
        <Leva fill />
      </KnobsContainer>

      <TidalThemeProvider
        className="commcenter-story-main-decorator"
        theme={themeConfig}
        style={{ width: '100%', height: '100%' }}
      >
        {children}
      </TidalThemeProvider>
    </>
  )
}
